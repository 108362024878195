import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import MetaverseRating from "./Components/MetaverseRating";
import MetaverseSlider from "./Components/MetaverseSlider";
import { IoMdClose } from "react-icons/io";
import ImageComp from "./Components/ImageComp";
import { BsStarFill, BsStar } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs";
import ReactPlayer from "react-player/youtube";
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from "react-paginate";
import { FacebookButton, FacebookCount, TwitterButton } from "react-social";
import MetaverseLatestVideo from "../../Components/MetaverseLatestVideo";
import MetaverseLatestScreenshots from "../../Components/MetaverseLatestScreenshots";
import avatar3 from "../../assets/images/avatar-3.jpg";
import bgTop from "../../assets/images/bg-top-5.png";

const SingleMetaversePage = () => {
  const userEmail = sessionStorage.getItem("userEmail");
  const [allReviews, setAllReviews] = useState([]); // all reviews of the metaverse
  const [userPostedReview, setPostedReview] = useState(false);

  const { id } = useParams();
  const [metaverse, setMetaverse] = useState("");
  const [metaverseVideos, setMetaverseVideos] = useState([]);
  const [metaverseSlider, setMetaverseSlider] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState({ filled: 0, empty: 5 });
  const [page, setPage] = useState(1);
  const limit = 4;
  const socialShareUrl = window.location.href;  

  const [visiblity, setVisiblity] = useState({
    description: true,
    review: false,
    videos: false,
  });

  const initialValues = {
    name: "",
    title: "",
    review: "",
    star: 0,
  };

  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${serverURL}metaverses/getSingleMetaverse`,
        {
          metaverseId: id,
        }
      );
      setMetaverse(response.data.message);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${serverURL}metaverses/metaverseVideos`,
          {
            metaverseId: id,
            limit,
            page,
          }
        );
        setMetaverseVideos(
          response?.data?.message ? [...response.data.message] : []
        );
        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [page]);

  function handlePageClick(e) {
    setPage(e.selected + 1);
  }
  const addPoint = async (obj) => {
    try {
      const responseP = await axios.post(`${serverURL}point/awardPoint`, {
        pointObj: obj,
      });      
    } catch (error) {
      console.log(error);
    }
  };
  function handleMetaversePageClick() {
    if (userEmail) {
      let pointObj = {
        email: userEmail,
        ActivityType: "Metaverse Page Share",
        ActivityName: metaverse.Name,
        ActivityId: id,
        Details: "Metaverse Page Share Details",
      };
      addPoint(pointObj);
    }
  }

  function videoPoint(videoUrl) {
    if (userEmail) {
      let pointObj = {
        email: userEmail,
        ActivityType: "Video Play",
        ActivityName: videoUrl,
        ActivityId: null,
        Details: "Video Details",
      };
      addPoint(pointObj);
    }
  }
  function addReviewPoint(activityId, nameR) {
    if (userEmail) {
      let pointObj = {
        email: userEmail,
        ActivityType: "Review Added",
        ActivityName: nameR,
        ActivityId: activityId,
        Details: "Review Added Details",
      };
      addPoint(pointObj);
    }
  }
  const checkReviewDone = async (obj) => {
    try {
      const response = await axios.post(`${serverURL}review/checkReview`, {
        reviewObj: obj,
      });

      if (response.status === 200) {
        if (response.data.exists) {
          setPostedReview(true);
        } else {
          //console.log(response.data.exists);
        }
      } else {
        alert("Something went wrong");
        //console.log(response.message);
      }
    } catch (error) {
      console.error("Error during review checking:", error);
      alert("Something went wrong. Please try again.");
    }
  };
  const getAllReviews = async () => {
    try {
      const response = await axios.post(`${serverURL}review/getReviews`, {
        metaverseId: id,
      });

      if (response.status === 200) {
        setAllReviews(response.data.reviews);        
      } else {
        alert("Something went wrong");        
      }
    } catch (error) {
      console.error("Error during review checking:", error);
      alert("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getAllReviews();
    if (userEmail) {
      const newObi = { userEmail, metaverseId: id };
      checkReviewDone(newObi);
    } else {
      setPostedReview(true);
    }
  }, []);
  const addReview = async (obj) => {
    try {
      const response = await axios.post(`${serverURL}review/addReview`, {
        reviewObj: obj,
      });

      if (response.status === 201) {
        setPostedReview(true);
        alert("Review Added Successfully");
        addReviewPoint(response.data.savedReview._id, obj.name);

        getAllReviews();
      } else {
        alert("Something went wrong");
        console.log(response);
      }
    } catch (error) {
      console.error("Error during add review:", error);
      alert("Something went wrong. Please try again.");
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        name: Yup.string().min(2).max(25).required("Please enter your name"),
        title: Yup.string().required("Please enter your title"),
        review: Yup.string().min(6).required("Please enter a review"),
      }),
      onSubmit: (values, action) => {
        if (userEmail) {
          let newReview = {
            name: values.name,
            title: values.title,
            review: values.review,
            metaverseId: id,
            email: userEmail,
          };

          addReview(newReview);
        }

        action.resetForm();
      },
    });

  return (
    <div className="container">
      <div className="py-8 ">
        <BreadCrumbs
          breadCrumbs={[
            { text: "HOME", link: "/" },
            { text: "METAVERSES", link: "/metaverses" },
          ]}
        />

        <div className="flex justify-start text-white font-bold items-end py-4 ">
          <h2 className="col-lg-3 text-2xl md:text-4xl w-fit pe-4">
            {" "}
            {metaverse.Name}
          </h2>
          <span className="col-lg-2 w-[86%] border-2 border-red-600"></span>
        </div>

        <div className="flex gap-x-8 items-start justify-between w-full  ">
          <div className="w-3/5 mt-10">
            <div className="flex flex-col md:flex-row justify-center h-96  items-start gap-4 h-full ">
              <div className="w-full md:w-1/2 h-[300px]">
                <ImageComp
                  src={metaverse.Image400x560}
                  setMetaverseSlider={setMetaverseSlider}
                />
              </div>
              <div className="w-full md:w-1/2 ">
                <h2 className="text-2xl font-bold text-white">
                  {metaverse.Name}
                </h2>
                <p className="text-base mt-4">{metaverse.Desc}</p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-start items-center  mt-11  w-full md:w-1/2 gap-4">
              <ImageComp
                src={metaverse.Image160x160A}
                setMetaverseSlider={setMetaverseSlider}
              />
              <ImageComp
                src={metaverse.Image160x160B}
                setMetaverseSlider={setMetaverseSlider}
              />
              <ImageComp
                src={metaverse.Image160x160C}
                setMetaverseSlider={setMetaverseSlider}
              />
            </div>

            <div className="flex justify-between items-center p-3 rounded bgLightGray mt-12">
              <div className="text-white font-bold">SHARE</div>
              <div className="flex justify-between items-center gap-4 text-3xl text-white ">
               {/*  <FacebookButton
                  url={socialShareUrl}
                  appId={679994227359801}
                  onClick={handleMetaversePageClick}
                >
                  <FaFacebook className="cursor-pointer" />
                </FacebookButton> */}
                <TwitterButton
                  url={socialShareUrl}
                  appId={28110354}
                  onClick={handleMetaversePageClick}
                >
                  <AiFillTwitterCircle className="cursor-pointer" />
                </TwitterButton>
              </div>
            </div>

            <div className="flex justify-evenly items-center flex-wrap gap-2 mt-4">
              <button
                onClick={() => {
                  setVisiblity({
                    review: false,
                    description: true,
                    videos: false,
                  });
                }}
                className={`${
                  visiblity.description ? "bg-red-600" : "bgLightGray"
                } hover:bg-red-600 px-4 py-2.5 bg-red-600 rounded text-white font-bold`}
              >
                DESCRIPTION
              </button>
              <button
                onClick={() => {
                  setVisiblity({
                    review: true,
                    description: false,
                    videos: false,
                  });
                }}
                className={`${
                  visiblity.review ? "bg-red-600" : "bgLightGray"
                } hover:bg-red-600 px-4 py-2.5 bg-red-600 rounded text-white font-bold`}
              >
                REVIEW
              </button>
              <button
                onClick={() => {
                  setVisiblity({
                    review: false,
                    description: false,
                    videos: true,
                  });
                }}
                className={`${
                  visiblity.videos ? "bg-red-600" : "bgLightGray"
                } hover:bg-red-600 px-4 py-2.5 bg-red-600 rounded text-white font-bold`}
              >
                VIDEO
              </button>
              {metaverse.mapUrl && (
                <Link target="_blank"
                  to={metaverse.mapUrl}
                  className="px-4 py-2.5 hover:bg-red-600 bgLightGray rounded text-white font-bold"
                >
                  MAP
                </Link>
              )}

              {metaverse.Url && (
                <Link target="_blank"
                  to={metaverse.Url}
                  className="px-4 py-2.5 hover:bg-red-600 bgLightGray rounded text-white font-bold"
                >
                  WEBSITE
                </Link>
              )}
            </div>

            {visiblity.description ? (
              <div className="mt-11">
                <div className="mt-10 text-base">{metaverse.Intro}</div>
                <div className="text-center gap-2 md:gap-0 md:text-left flex flex-col md:flex-row justify-between items-center mt-12">
                  <div className="border-2 md:border-none border-gray-700 w-full p-3 md:w-2/6 flex justify-center gap-2 items-center">
                    {/* <div className='h-[50px] w-[60px]'>
                                    <img src={metaverse.Logo} alt=""  className='h-full w-full'/>
                                </div> */}
                    <div>
                      <span className="text-white text-base mb-8">
                        Pegi Rating:
                      </span>
                      <p className="mt-10">{metaverse.AgeRating}</p>
                    </div>
                  </div>
                  <div className="border-r-[1px]  border-gray-700 h-[100px] hidden md:block">
                    {" "}
                  </div>

                  <div className="border-2 md:border-none border-gray-700 w-full p-3 md:w-2/6 ">
                    <span className="text-white text-base mb-8">Genre:</span>
                    <p className="mt-10">{metaverse.Genre}</p>
                  </div>

                  <div className="border-r-[1px] border-gray-700  h-[100px] hidden md:block">
                    {" "}
                  </div>

                  <div className="border-2 md:border-none border-gray-700 flex justify-center items-center flex-col w-full p-3 md:w-2/6 text-center ">
                    <span className="text-white text-base ">
                      Customer Rating:
                    </span>

                    <div className="flex justify-evenly items-center mt-10 font-bold text-xl">
                      <MetaverseRating metaverseId={id} />
                    </div>
                  </div>
                </div>
              </div>
            ) : visiblity.review ? (
              <div className="mt-11">
                {!userPostedReview && (
                  <div>
                    <h2 className="text-xl font-bold text-white ">
                      ADD A REVIEW
                    </h2>

                    <form className="w-full" onSubmit={handleSubmit}>
                      <div className="gap-4  flex justify-between items-center py-4 w-full ">
                        <div className="w-1/2">
                          <input
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Name *"
                            name="name"
                            className="rounded w-full bg-transparent px-4 py-2 outline-none border-2 focus:border-red-600 border-gray-700"
                          />
                          {errors.name && touched.name ? (
                            <p className="form-error text-red-600">
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="w-1/2">
                          <input
                            type="text"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Title *"
                            name="title"
                            className="rounded w-full bg-transparent px-4 py-2 outline-none  border-2 border-gray-700 focus:border-red-600"
                          />
                          {errors.title && touched.title ? (
                            <p className="form-error text-red-600">
                              {errors.title}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <textarea
                        name="review"
                        value={values.review}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Your Review *"
                        rows="5"
                        className="rounded w-full bg-transparent px-4 py-2 outline-none  border-2 border-gray-700 focus:border-red-600"
                      />
                      {errors.review && touched.review ? (
                        <p className="form-error text-red-600">
                          {errors.review}
                        </p>
                      ) : null}

                      <div className="flex justify-between items-center mt-10">
                        <div className="w-1/2 flex justify-start items-center gap-2">
                          {new Array(stars.filled)
                            .fill(0)
                            .map((item, index) => (
                              <BsStarFill className="text-gray-50 cursor-pointer" />
                            ))}

                          {new Array(stars.empty).fill(0).map((item, index) => (
                            <BsStar
                              className="text-gray-50 cursor-pointer"
                              onClick={() => {
                                setStars({ filled: index + 1, empty: 0 });
                                console.log("starts are", stars);
                              }}
                            />
                          ))}
                        </div>

                        <button className=" text-sm px-4 py-2.5 bgLightGray rounded text-white font-bold">
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {/* START: Review */}
                {allReviews.length > 0 && (
                  <div className="nk-comments">
                    {allReviews
                      .sort(
                        (a, b) =>
                          new Date(b.datePosted) - new Date(a.datePosted)
                      )
                      .map((reviewObj) => (
                        <div key={reviewObj._id} className="nk-comment">
                          <div className="nk-comment-meta">
                            <img
                              src={avatar3}
                              alt="Wolfenstein"
                              className="rounded-circle"
                              width="35"
                            />
                            by <a href="https://nkdev.info">{reviewObj.name}</a>{" "}
                            in {new Date(reviewObj.datePosted).toLocaleString()}
                            <span className="nk-product-rating nk-review-rating">
                              <span
                                className="nk-product-rating-front"
                                style={{ width: "100%" }}
                              >
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                              </span>
                              <span className="nk-product-rating-back">
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                              </span>
                            </span>
                          </div>
                          <div className="nk-comment-text">
                            <p>{reviewObj.review}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* END: Review */}
              </div>
            ) : (
              <div className="mt-11  px-4 ">
                <div className="flex justify-start text-white font-bold items-end py-4 mb-4">
                  <span className="w-full md:w-[35%] lg:w-[25%]  text-xl md:text-2xl">
                    {" "}
                    <span className="text-red-500">VIDEO</span> GALLERIES
                  </span>
                  <span className="hidden md:block md:w-[65%] lg:w-[75%] border-2 border-red-600"></span>
                </div>

                {metaverseVideos && metaverseVideos[0] ? (
                  <div>
                    {loading ? (
                      <div className="flex justify-center items-center h-1/2 w-full py-8 ">
                        <BeatLoader color="rgba(214, 54, 54, 1)" />
                      </div>
                    ) : (
                      <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                          {metaverseVideos?.map((url, index) => (
                            <div key={index}>
                              <ReactPlayer
                                url={url}
                                width="100%"
                                height="100%"
                                onPlay={() => videoPoint(url)}
                                //can use onEnded={}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="mt-3.5 w-full flex justify-center  items-center text-xl  font-bold">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={limit}
                            pageCount={totalPages}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            activeClassName="text-red-600 border-2 border-red-500 rounded-full p-8"
                            containerClassName="flex items-center justify-evenly text-white w-5/12 my-4 "
                            pageLinkClassName="hover:text-red-600"
                            forcePage={page - 1}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`p-4 w-full font-bold text-white border-2 border-red-600`}
                  >
                    No Videos available, please check again later.
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="w-2/5 ">
            <MetaverseLatestVideo metaverseId={id} />
            <MetaverseLatestScreenshots metaverseId={id} />
          </div>
        </div>
      </div>
      {metaverseSlider && (
        <div className="fixed top-0 left-0 z-50 p-20 h-screen w-screen transparentBlack ">
          <div className="flex justify-end ">
            <IoMdClose
              onClick={() => setMetaverseSlider(false)}
              className="text-white text-3xl  rounded-full cursor-pointer  hover:scale-125"
            />
          </div>
          <div>
            <MetaverseSlider
              carouselData={[
                { img: metaverse.Image400x560 },
                { img: metaverse.Image160x160A },
                { img: metaverse.Image160x160B },
                { img: metaverse.Image160x160C },
              ]}
            />
          </div>
        </div>
      )}

      <div className="nk-gap-4"></div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default SingleMetaversePage;
