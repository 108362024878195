import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/main_logo.png";
import axios from "axios";
import { FaUser } from "react-icons/fa";
import { serverURL } from "./../../utils/serverURL";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkToken = async () => {
    try {
      const tokenValue = sessionStorage.getItem("token");

      if (tokenValue) {
        const response = await axios.post(
          `${serverURL}user/verifyToken`,
          {
            token: tokenValue,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.status === 200) {
          setIsLoggedIn(true);
        } else {          
          setIsLoggedIn(false);
          alert("not logged");
        }
      }
    } catch (error) {
      console.log("Error in token checking");
      console.log(error);
      setIsLoggedIn(false);
    }
  };
  useEffect(() => {
    checkToken();
  }, []);
  function logOut() {
    // Remove the "token" item from sessionStorage
    sessionStorage.removeItem("token");
    window.location.href = "/login";
  }
  function mouseHandler(bool) {
    setHovered1(bool);
  }

  return (
    <nav className="bgLightBlack py-3.5  hidden lg:block">
      <div className="container px-9 flex justify-between items-center">
        <div>
          <Link to="/" className="">
            <img src={logo} alt="Rare Land" width="199" />
          </Link>
        </div>
        <div className="flex justify-evenly items-center gap-4 text-white">
          <div
            className="relative py-4"
            onMouseEnter={() => mouseHandler(true)}
            onMouseLeave={() => mouseHandler(false)}
          >
            <Link to="/metaverses" className="font-bold text-lg">
              METAVERSES
            </Link>         
          </div>

          <Link to="" className="font-bold text-lg ">
            MARKETPLACE +
          </Link>
          <Link to="/profile">
            <FaUser />
          </Link>
          {isLoggedIn && <button onClick={logOut}>LOG OUT</button>}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
