import React, { useEffect, useState } from "react";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import bgTop from "../../assets/images/bg-top.png";

const Profile = () => {
  const userEmail = sessionStorage.getItem("userEmail");
  const userName = sessionStorage.getItem("userName");
  const [allPoints, setPoints] = useState([]);

  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
    walletAddress: "",
  });
  const getAllPoints = async () => {
    try {
      const response = await axios.get(
        `${serverURL}point/getPoints?email=${userEmail}`
      );

      if (response.status === 200) {
        setPoints(response.data.points);        
      } else {
        alert("Something went wrong");        
      }
    } catch (error) {
      console.error("Error during getAllPoints checking:", error);
      alert("Something went wrong. Please try again.");
    }
  };
  const updateCredentials = async () => {
    const response = await axios.post(`${serverURL}user/updateUser/`, {
      credentials: userCredentials,
    });    
    if (response.status === 200) {
      alert("Credentials updated Successfully");
    } else {
      alert("Error in updating credentials");
    }
  };
  const getWalletAddress = async () => {
    try {
      const response = await axios.get(
        `${serverURL}user/getUser?email=${userEmail}`
      );
      
      if (response.status === 200) {
        setCredentials((prevCre) => {
          return { ...prevCre, walletAddress: response.data.userWalletAddr };
        });        
      } else {
        alert("Something went wrong getWalletAddress");        
      }
    } catch (error) {
      console.error("Error during wallet address fetching:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    if (userEmail) {
      getAllPoints();
      setCredentials((prevCre) => {
        return { ...prevCre, email: userEmail };
      });
      getWalletAddress();
    }
  }, []);

  return (
    <>
      <div className="nk-main">
        <div className="nk-gap-2"></div>
        <div className="container">
          <ul className="nk-breadcrumbs">
            <li>
              <span className="fa fa-angle-right"></span>
            </li>
            <li>
              <span>Profile</span>
            </li>
          </ul>
          <br />
          <br />
          <div className="profileFormContainer">
            <h2>{userName}</h2>
            <form>
              <input
                className="loginformInput"
                type="password"
                id="password"
                value={userCredentials.password}
                onChange={(e) =>
                  setCredentials({
                    ...userCredentials,
                    password: e.target.value,
                  })
                }
                placeholder=" Password"
              />
              <br />
              <input
                className="loginformInput"
                type="text"
                id="walletAddress"
                value={userCredentials.walletAddress}
                onChange={(e) =>
                  setCredentials({
                    ...userCredentials,
                    walletAddress: e.target.value,
                  })
                }
                placeholder=" Wallet Address"
              />
              <br />
              <br />
              <button
                type="button"
                className="btnL"
                onClick={updateCredentials}
              >
                Update
              </button>
            </form>
          </div>
        </div>

        <img className="nk-page-background-top" src={bgTop} alt="" />
      </div>
      <div className="nk-store nk-store-checkout container">
        <div className="nk-gap-2"></div>

        {/* START: Total Points */}
        <div className="nk-gap-3"></div>
        <h3 className="nk-decorated-h-2">
          <span>
            <span className="text-main-1">Your</span> Points
          </span>
        </h3>
        <div className="nk-gap"></div>
        <div className="table-responsive">
          <table className="nk-table nk-table-sm">
            <thead className="thead-default">
              <tr>
                <th className="nk-product-cart-date">Date</th>
                <th className="nk-product-cart-type">Type</th>
                <th className="nk-product-cart-Activity">Activity</th>
                <th className="nk-product-cart-Point"> Total</th>
              </tr>
            </thead>
            <tbody>
              {/* START: Point */}
              {allPoints.length > 0 && (
                <>
                  {allPoints.map((pointObj) => (
                    <tr key={pointObj._id}>
                      <td className="nk-product-cart-title">
                        {new Date(pointObj.DateReceived).toLocaleString()}
                      </td>
                      <td className="nk-product-cart-total">
                        {pointObj.ActivityType}
                      </td>
                      <td className="nk-product-cart-ActivityTitle">
                        {pointObj.ActivityName}
                      </td>
                      <td className="nk-product-cart-Point">1</td>
                    </tr>
                  ))}

                  <tr>
                    <td className="nk-product-cart-title">Total Points:</td>
                    <td className="nk-product-cart-total"></td>
                    <td className="nk-product-cart-ActivityTitle"></td>
                    <td className="nk-product-cart-Point">
                      {/* Calculate total points using reduce */}
                      {allPoints.reduce((total, pointObj) => total + 1, 0)}
                    </td>
                  </tr>
                </>
              )}

              {/* END: Review */}
            </tbody>
          </table>
        </div>
        {/* END: Order Products */}
        <div className="nk-gap-2"></div>
      </div>
    </>
  );
};

export default Profile;