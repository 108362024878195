import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { serverURL } from "../../utils/serverURL";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import bgTop from "../../assets/images/bg-top.png";

const Reset = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [userCredentials, setCredentials] = useState({
    token: "",
    password: "",
    repassword: "",
  });
  useEffect(() => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      token: token || "", // Update the token property with the value from the URL
    }));
  }, [searchParams]); // Add tokenFromUrl to the dependency array
  const updateCredentials = async () => {
    if (userCredentials.password !== userCredentials.repassword) {
      alert("Passwords do not match");
      return;
    }
    // Exclude repassword from the user data
    const { repassword, ...userData } = userCredentials;

    try {
      const response = await axios.post(`${serverURL}user/reset/`, {
        credentials: userCredentials,
      });      

      if (response.status === 200) {
        alert("Password Reset Successfully");

        window.location.href = "/login";
      } else {
        alert("Something went wrong");        
      }
    } catch (error) {
      console.error("Error during reset password:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="mainContainerL">
      <div className="loginContainer">
        <h2>
          <span className="signL">Reset</span>{" "}
          <span className="inL">Password</span>
        </h2>
        <br />
        <form>
          <input
            className="loginformInput"
            type="password"
            id="password"
            value={userCredentials.password}
            onChange={(e) =>
              setCredentials({ ...userCredentials, password: e.target.value })
            }
            placeholder=" Enter Password"
          />
          <br />
          <input
            className="loginformInput"
            type="password"
            id="repassword"
            value={userCredentials.repassword}
            onChange={(e) =>
              setCredentials({ ...userCredentials, repassword: e.target.value })
            }
            placeholder=" Enter Password Again"
          />
          <br />
          <br />
          <div className="btnPO">
            <button type="button" className="btnL" onClick={updateCredentials}>
              Update Password
            </button>
          </div>
        </form>
      </div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default Reset;
