import React, { useEffect, useState } from "react";
import Highlight from "./Components/Highlight";
import ImageSlider from "./Components/ImageSlider";
import bgTop from "../../assets/images/bg-top.png";
import LatestNews from "./Components/LatestNews";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import { secretKey } from "../../utils/secretKey";

const HomePage = () => {
  const [news, setNews] = useState([]);

  const fetchData = async () => {
    const response = await axios.post(`${serverURL}news/`, { key: secretKey });    
    if (response.status === 200) {
      setNews(response.data.news);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="nk-gap-2" />
      <ImageSlider />
      <div className="nk-gap-2" />
      <Highlight />
      <div className="nk-gap-2" />
      <LatestNews news={news} />
      <div className="nk-gap-2" />
      <div className="nk-gap-4"></div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default HomePage;
