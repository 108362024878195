import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/main_logo.png";
import MobileNav from "./MobileNav";
import BoxComponent from "./BoxComponent";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useEffect } from "react";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  const ref = useRef();

  function mouseHandler(bool) {
    setHovered1(bool);
  }

  useEffect(() => {
    if (open) {
      ref.current.style.right = "0px";
    } else {
      ref.current.style.right = "-500px";
    }
  }, [open]);
  return (
    <nav className="bgLightBlack py-4 block lg:hidden">
      <div className="container flex justify-between items-center">
        <div>
          <Link to="/" className="">
            <img src={logo} alt="Rare Land" width="199" />
          </Link>
        </div>
        <div>
          <GiHamburgerMenu
            onClick={() => setOpen(true)}
            className="text-3xl text-white hover:text-red-600 hover:cursor-pointer"
          />
        </div>
      </div>

      <div>
        <div
          ref={ref}
          className="flex flex-col  gap-4 bg-zinc-900	 border-l-2 border-red-500 fixed z-40 top-0  h-screen  w-1/2 md:w-1/4 text-white font-bold p-14 "
        >
          <IoMdClose
            onClick={() => setOpen(false)}
            className="text-3xl cursor-pointer hover:scale-125 text-white absolute top-5 right-10 border-2 border-red-600 rounded-full"
          />
          <div
            className="relative py-3"
            onMouseOver={() => setHovered1(true)}
            onMouseLeave={() => setHovered1(false)}
          >
            <Link to="/metaverses" className="font-bold text-xl">
              Metaverses
            </Link>            
          </div>
          <div
            className="relative py-3"
            onMouseOver={() => setHovered2(true)}
            onMouseLeave={() => setHovered2(false)}
          >
            <Link to="" className="font-bold text-xl">
              Blog +
            </Link>
            {hovered2 ? (
              <BoxComponent
                mouseHandler={setHovered2}
                links={[
                  { link: "BACK", src: "#" },
                  { link: "NEWS", src: "#" },
                  { link: "BLOG FULLWIDTH", src: "#" },
                  { link: "BLOG ARTICLE", src: "#" },
                ]}
              />
            ) : null}
          </div>
          <Link className="py-3">GALLERY</Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;