import React, { useEffect, useState } from "react";
import LatestScreenshots from "../../Components/LatestScreenshots";
import LatestVideo from "../../Components/LatestVideo";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import { secretKey } from "../../utils/secretKey";
import { useParams } from "react-router-dom";
import NewsManager from "./Components/NewsManager";
import BreadCrumbs from "../../Components/BreadCrumbs";

const NewsPage = (props) => {
  const [post, setPost] = useState(null);
  const params = useParams();
  let { slug } = params;
  slug = "a-glance-at-big-time-621vmn";
  const fetchData = async () => {
    const response = await axios.post(`${serverURL}news/${slug}`, {
      key: secretKey,
    });
    if (response.status === 200) {
      setPost(response.data.news);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="py-8 ">
        <BreadCrumbs breadCrumbs={[{ text: "HOME", link: "/" }]} />

        <div className="flex justify-start text-white font-bold items-end py-4 ">
          <h2 className="col-lg-3 text-4xl w-fit pe-4"> NEWS</h2>
          <span className="col-lg-2 w-[86%] border-2 border-red-600"></span>
        </div>

        <div className="flex gap-x-8 items-start justify-between w-full  ">
          <div className="w-3/5 ">
            <NewsManager />
          </div>

          <div className="w-2/5 ">
            <LatestVideo />
            <LatestScreenshots />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
