import { Carousel } from '@sefailyasoz/react-carousel'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { CCarousel,CCarouselItem,CImage } from '@coreui/react'


const MetaverseSlider = ({carouselData}) => {

      

  return  ( 
    <div className='h-full w-full flex justify-center items-center py-20'>
        <CCarousel controls className='h-full'>
            {
                carouselData.map(({img})=>(
                    <CCarouselItem>
                        <CImage className='w-full md:w-10/12 mx-auto px-8' src={img}  />
                    </CCarouselItem>
                ))

            }
        </CCarousel>
    </div>)
}

export default MetaverseSlider



