import React from "react";
import dclLogo from "../../../assets/images/DCL_Logo.png";
import sandboxLogo from "../../../assets/images/Sandbox_Logo.png";
import axieLogo from "../../../assets/images/Otherside_logo.png";

const Highlight = () => {
  return (
    <div className="row vertical-gap">
      <div className="col-lg-4">
        <div className="nk-feature-1">
          <div className="nk-feature-icon">
            <img src={dclLogo} alt="" />
          </div>
          <div className="nk-feature-cont">
            <h3 className="nk-feature-title">
              <a href="#">DCL</a>
            </h3>
            <h4 className="nk-feature-title text-main-1">
              <a href="https://opensea.io/collection/decentraland">View Lands</a>
            </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="nk-feature-1">
          <div className="nk-feature-icon">
            <img src={sandboxLogo} alt="" />
          </div>
          <div className="nk-feature-cont">
            <h3 className="nk-feature-title">
              <a href="https://opensea.io/collection/sandbox">SANDBOX</a>
            </h3>
            <h4 className="nk-feature-title text-main-1">
              <a href="#">View Lands</a>
            </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="nk-feature-1">
          <div className="nk-feature-icon">
            <img src={axieLogo} alt="" />
          </div>
          <div className="nk-feature-cont">
            <h3 className="nk-feature-title">
              <a href="https://opensea.io/collection/otherdeed">Otherside</a>
            </h3>
            <h4 className="nk-feature-title text-main-1">
              <a href="https://opensea.io/collection/otherdeed-expanded">View Lands</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlight;
