import React, { useState } from "react";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import bgTop from "../../assets/images/bg-top.png";
import ReCAPTCHA from "react-google-recaptcha";
import { EmailButton } from "react-social";

const Login = () => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const validateInputs = () => {
    const { email, password } = userCredentials;

    if (!email.trim()) {
      alert("Please enter an email.");
      return false;
    }

    if (!password.trim()) {
      alert("Please enter a password.");
      return false;
    }

    return true;
  };
  const handleRecaptchaChange = (value) => {    
    // Set the state based on the verification status
    setIsCaptchaVerified(value !== null);
  };
  const checkCredentials = async (e) => {
    e.preventDefault();
    if (!isCaptchaVerified) {
      alert("Please verify captcha first");
      return;
    }
    if (!validateInputs()) {
      return;
    }

    try {
      const response = await axios.post(`${serverURL}user/checkUser`, {
        credentials: userCredentials,
      });      

      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("userEmail", userCredentials.email);
        sessionStorage.setItem("userName", response.data.username);

        try {          
          let pointObj = {
            email: userCredentials.email,
            ActivityType: "login",
            ActivityName: "Logging Into Account",
            ActivityId: null,
            Details: "Some Login Details",
          };
          const responseP = await axios.post(`${serverURL}point/awardPoint`, {
            pointObj,
          });          
        } catch (error) {
          console.log(error);
        }
        alert("Login Successful!");
        window.location.href = "/";
      } else if (response.status === 406) {
        alert("Credentials are incorrect");
      } else {
        alert("Something went wrong");        
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="mainContainerL">
      <div className="loginContainer">
        <h2>
          <span className="signL">Sign</span> <span className="inL">In</span>
        </h2>
        <br />
        <div className="descL">Use email and password:</div>
        <form>
          <input
            className="loginformInput"
            type="email"
            id="email"
            value={userCredentials.email}
            onChange={(e) =>
              setCredentials({ ...userCredentials, email: e.target.value })
            }
            placeholder=" Email"
          />
          <br />

          <input
            className="loginformInput"
            type="password"
            id="password"
            value={userCredentials.password}
            onChange={(e) =>
              setCredentials({ ...userCredentials, password: e.target.value })
            }
            placeholder=" Password"
          />
          <br />
          <br />
          <ReCAPTCHA sitekey="6Lch6xcpAAAAABKQ28IX_MUal7xGadlZTM1D1NAg" onChange={handleRecaptchaChange} />
          <div>
            <button type="button" className="btnL" onClick={checkCredentials}>
              Sign In
            </button>            
          </div>
          <div className="otherLinksL">
              <Link to="/forgot">Forgot Password?</Link>
              <Link to="/registration">Not a member? Sign up</Link>
          </div>
        </form>
      </div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default Login;
