import React, { useEffect, useState } from "react";
import Highlight from "./Components/Highlight";
import ImageSlider from "./Components/ImageSlider";
import bgTop from "../../assets/images/bg-top-4.png";
import Chains from "./Components/Chains";
import BreadCrumbs from "../../Components/BreadCrumbs";

const MetaversesPage = () => { 

  const [news, setNews] = useState([]);

  

  return (
    <div className="container">
      
      <div className="nk-gap-2" />
      <BreadCrumbs breadCrumbs={[{text:"HOME",link:"/"}]} />
      <div className="nk-gap-2" />


      <ImageSlider />
      <div className="nk-gap-2" />
      <Highlight />
      <div className="nk-gap-4" />     
      <div>
        <Chains/>
      </div>
      <div className="nk-gap-4"></div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default MetaversesPage;
