import React from "react";
import { Link } from "react-router-dom";
import { ChevronForwardOutline } from "react-ionicons";

const Title = ({ title }) => {
  return (
    <ul className="nk-breadcrumbs">
      
      <li>
        <span>{title}</span>
      </li>
    </ul>
  );
};

export default Title;
