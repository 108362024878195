import React, { useState } from "react";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import bgTop from "../../assets/images/bg-top.png";

const Forgot = () => {
  const [emailF, setEmail] = useState("");
  const validateInputs = () => {
    if (!emailF.trim()) {
      alert("Please enter an email.");
      return false;
    }

    return true;
  };
  const sendEmail = async () => {
    if (!validateInputs()) {
      return;
    }
    try {
      const response = await axios.post(`${serverURL}user/sendEmail`, {
        email: emailF,
      });

      if (response.data.message === "InvalidCredentials") {
        alert("Please provide a valid email");
      } else if (response.status === 200) {
        alert("Reset password link send to your email successfully");
      } else {
        alert("Failed to send reset password link");
      }
    } catch (error) {
      alert("Please try again");
      console.log(error);
    }
  };

  return (
    <div className="mainContainerL">
      <div className="loginContainer"> 
        <h2>
          <span className="signL">Forgot</span>{" "}
          <span className="inL">Password</span>
        </h2>
        <br />
        <div className="descL">
          Enter email address to get reset password link:
        </div>
        <form>
          <input
            className="loginformInput"
            type="email"
            id="emailF"
            value={emailF}
            onChange={(e) => setEmail(e.target.value)}
            placeholder=" Email"
          />

          <button type="button" className="btnL" onClick={sendEmail}>
            Send Reset Link
          </button>
        </form>
      </div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default Forgot;