import React,{useEffect,useState} from 'react'
import axios from "axios"
import { serverURL } from '../../../utils/serverURL';
import { BsStarFill } from "react-icons/bs";
import { BsStarHalf } from "react-icons/bs";

const MetaverseRating = ({metaverseId}) => {
    const [metaverseRating,setMetaverseRating] = useState(0);

    useEffect(()=>{
        (async ()=>{
            try{
                const response = await axios.post(`${serverURL}metaverses/avgRating`,{
                    metaverseId:metaverseId
                })
                setMetaverseRating(response.data.message[0].avgRating)
            }catch(error){
                console.log(error)
            }
          
        })()
    },[])
    const floorValue = Math.floor(metaverseRating);
    const difference = metaverseRating-floorValue;
    const remaining = 5-Math.ceil(metaverseRating);
    
  return (
    <div className='text-red-600 text-base font-bold flex justify-start items-start  w-full gap-1 my-2 '>
      
            {
                floorValue? new Array(floorValue).fill(0).map(()=>(<BsStarFill />)):null
            }

            {  difference? <BsStarHalf />:null} 
           
            { remaining ? new Array(remaining).fill(0).map(()=>(<BsStarFill className='text-gray-700'/>)):null
            }

            <span className="ml-2" >{metaverseRating?metaverseRating:null}</span>     
    </div>
  )
}

export default MetaverseRating