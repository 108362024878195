import React from 'react'
import { Link } from "react-router-dom";


const BoxComponent = ({links,mouseHandler}) => {
  return (
    <div className='rounded bgDarkBlack absolute top-14 p-10 border-b-2 border-red-600 w-[250px] z-50' onMouseOver={()=>mouseHandler(true)} onMouseLeave={()=>mouseHandler(false)}>
        {links.map(({link,src})=>(
            <Link to={src} className='py-2 font-bold block '>{link}</Link>
        ))}
    </div>
  )
}

export default BoxComponent