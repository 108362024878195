import {useEffect,useState} from 'react'
import { IoStarSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import MetaverseRating from './MetaverseRating';

const MetaverseCard = ({id,rating,name,image,intro}) => {

    const slicedIntro = intro?.slice(0,30);

  return (
    <Link to={`/metaverses/${id}`}>
        <div className='flex justify-center items-start gap-2 h-48 '>

            <div className='w-1/2 sm:h-full lg:h-full md:h-2/3'> 
                <img src={image} alt="" className='h-full'/>
            </div>
            <div className='w-1/2 text-lg md:text-base lg:text-lg px-2'>
                <h2 className='text-white font-bold'>{name}</h2>
                <div className='mt-10 w-full '>
                    <MetaverseRating metaverseId={id}/>
                </div>

                <div>
                    <p className='text-sm md:text-base'>{slicedIntro}....</p>
                </div>
            </div>
            
        </div>
    </Link>
  )
}

export default MetaverseCard