import axios from "axios";
import React, { useEffect, useState } from "react";
import gallary from "../assets/images/gallery-1.jpg";
import { serverURL } from "../utils/serverURL";
import { Eye } from "react-ionicons";
import ImageComp from "../Pages/MetaversesPage/Components/ImageComp";
import { Link } from "react-router-dom";

const LatestScreenshots = () => {
  const [screenshots, setScreenshots] = useState([]);

  const fetchData = async () => {
    const response = await axios.get(`${serverURL}screenshot/`);
    if (response.status === 200) {
      setScreenshots(response.data.screenshots);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="nk-widget nk-widget-highlighted mt-4">
        <h4 className="nk-widget-title">
          <span>
            <span className="text-main-1">Latest</span> Screenshots
          </span>
        </h4>       
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 bgDarkGray px-2 py-4 place-content-center place-items-center">
              {
                screenshots.map((shots,index)=>(
                  <div key={index} className="w-[130px] h-[100px] lg:w-[160px] lg:h-[100px] relative">
                      
                    <Link to={shots.url} target="_blank">
                      <ImageComp src={shots.url} />
                    </Link>
                     
                  </div>
                ))
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestScreenshots;
