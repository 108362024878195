import React,{useEffect,useState} from 'react'
import axios from "axios"
import { serverURL} from '../../../utils/serverURL';
import ReactPaginate from 'react-paginate';
import BeatLoader from "react-spinners/BeatLoader";
import MetaverseCard from './MetaverseCard';


const MetaverseManager = ({selectedMetaverse,page,setPage}) => {
    
    const [metaverseArticles,setMetaverseArticles] = useState([]);
    const [totalPages,setTotalPages] = useState(0)
    const [loading,setLoading] = useState(false);
    const limit = 4;

    
    useEffect(()=>{
        (async()=>{
            try{
                setLoading(true);
                const response = await axios.post(`${serverURL}metaverses/getAllMetaverses`,{
                    chainId:selectedMetaverse,
                    page:page,
                    limit:limit
                });
                setMetaverseArticles([...response?.data?.message?.metaverses])
                setTotalPages(response?.data?.message?.totalPages)
                setLoading(false)
            }catch(error){
                console.log(error)
                setLoading(false)
            }
           
        })()
    },[selectedMetaverse,page])

    function handlePageClick(e){
      
        setPage(e.selected + 1)
    }


  return (
    
    <div className='my-4 '>
       
       {
            loading?
            <div className='flex justify-center items-center h-1/2 w-full py-8' ><BeatLoader color="rgba(214, 54, 54, 1)" /></div>:
            <div className={`grid sm:grid-cols-1 md:grid-cols-2 gap-6 mt-4 px-2`}>
                    {
                        metaverseArticles.length > 0? metaverseArticles?.map(({_id,Name,Image160x160A,Intro})=>(
                                <MetaverseCard id={_id} name={Name} image={Image160x160A} intro={Intro}/>
                            ))
                        :<div className={`p-4 w-full font-bold text-white border-2 border-red-600`}>No Metaverses available, please check again later.</div>
                    }
            </div>
        }
       
        <div>
           
       
            <div className='w-full mt-3.5 flex justify-center items-center text-xl md:text-2xl font-bold'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={limit}
                    pageCount={totalPages}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    activeClassName= "text-red-600 border-2 border-red-600 rounded-full p-8"
                    containerClassName="flex items-center justify-evenly text-white w-6/12 my-4 "
                    pageLinkClassName="hover:text-red-600"
                    forcePage={page-1}
                />


            </div>

        </div>
      

            
    </div>
  )
}

export default MetaverseManager