import React,{useEffect,useState} from 'react'
import axios from "axios"
import { serverURL} from '../../../utils/serverURL';
import NewsCard from './NewsCard';
import ReactPaginate from 'react-paginate';
import BeatLoader from "react-spinners/BeatLoader";


const NewsManager = () => {
    const [newsCategory,setNewsCategory] = useState([]);
    const [selected,setSelected] = useState('6550fbc7c76b961fd6df0811');
    const [page,setPage] = useState(1);
    const [newsArticles,setNewsArticles] = useState([]);
    const [totalPages,setTotalPages] = useState(0)
    const [loading,setLoading] = useState(false);
    const limit = 5;


    useEffect(()=>{
        (
            async ()=>{
                try{
                    const response = await axios.get(`${serverURL}category`);
                    setNewsCategory(response.data.categories);
                }catch(error){
                    console.log(error)
                }
               

            }
        )()

      
    },[])
    useEffect(()=>{
        (async()=>{
            try{
                setLoading(true);
                const response = await axios.post(`${serverURL}news/pagination`,{
                    category:selected,
                    page:page,
                    limit,
                })
                setNewsArticles([...response?.data?.message?.news])
                setTotalPages(response?.data?.message?.totalPages)
                setLoading(false)
               

            }catch(error){
                console.log(error)
                setLoading(false)
            }
           
        })()
    },[selected,page])

    function handlePageClick(e){
      
        setPage(e.selected + 1)
    }

    function categoryHandler(_id){
        setSelected(_id)
        setPage(1)
    }
   
  return (
    
    <div className='my-4 '>
        <div className='flex flex-wrap w-full justify-evenly items-center'>
            {
                newsCategory?.map(({_id,name},i)=>(
                    <button onClick={()=>categoryHandler(_id)} className={` px-8 py-2 rounded font-bold mx-2 my-2 text-white ${(selected==_id)? 'bg-red-600':'bgGrayColor'} `} key={i}>{name.toUpperCase()}</button>
                )) 
            }
        </div>
       
       {
            loading?
            <div className='flex justify-center items-center h-1/2 w-full py-8' ><BeatLoader color="rgba(214, 54, 54, 1)" /></div>:
            <div className={`grid sm:grid-cols-1 md:grid-cols-2 gap-6 mt-4`}>
                    {
                        newsArticles.length > 0? newsArticles?.map(({title,body,slug,createdAt,categories,imageMedium},i)=>(
                            <div key={i}>
                                <NewsCard createdAt={createdAt} imgSrc={`/uploads/newsPhoto/image-1697805324439.jpeg`} title={title} slug={slug} category={(categories[0]?.name)? categories[0].name :""} body={body}/>
                            </div>
                            ))
                        :<div className={`p-4 w-full font-bold text-white border-2 border-red-600`}>No News available, please check again later.</div>
                    }
            </div>
        }
       
        <div>
           
       
            <div className='w-full flex justify-center items-center text-2xl font-bold'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={limit}
                    pageCount={totalPages}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    activeClassName= "text-red-600 border-2 border-red-600 rounded-full p-8"
                    containerClassName="flex items-center justify-evenly text-white w-6/12 my-4 "
                    pageLinkClassName="hover:text-red-600"
                    forcePage={page-1}
                />


            </div>

        </div>
      

            
    </div>
  )
}

export default NewsManager