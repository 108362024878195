import {useEffect,useState} from 'react';
import axios from "axios";
import { serverURL } from '../../../utils/serverURL';
import MetaverseCard from './MetaverseCard';
import MetaverseManager from './MetaverseManager';


const Chains = () => {
    const [chains,setChains] = useState([]);
    const [metaverses,setMetaverses] = useState([]);
    const [selectedMetaverse,setSelectedMetaverse] = useState("65778606d99781325f58412c");
    const [page,setPage] = useState(1);

    useEffect(()=>{
        async function chainsFetcher(){
            const response = await axios.get(`${serverURL}chains/getAllChains`);
            setChains(response.data)            
        }
        chainsFetcher();
    },[])



  return (
    <div className='flex gap-0 justify-between items-start flex-col md:flex-row w-full'>
      <div className='md:order-2 w-full mt-10 p-10 md:p-0 text-lg md:w-2/6 h-full overflow-y-auto '>
            <div className='text-xl w-full flex justify-between items-center gap-2 font-bold py-3 px-4 rounded-tb-lg bgLightGray'>
              <div >
                <span className='text-red-600 mr-2'>CHAINS</span> 
                <span className=''>MENU</span> 
              </div>
              <span className='border-b-4 border-gray-50 w-1/2'>   </span>
            </div>
           
            <div className='w-full'>
                {
                  chains?.map(({image,name,_id})=>(
                  <div 
                  
                    onClick={()=>{setSelectedMetaverse(_id)
                    setPage(1)
                  }}
                  
                  className='cursor-pointer w-full flex justify-between items-center gap-4 py-2 px-4 bgChainDark '>
                      <div className='w-1/12 md:w-2/12'>
                          <img src={image} alt={name} className='w-full' />
                      </div>
                      <div className='text-white text-lg w-11/12 md:w-10/12'>{name}</div>
                  </div>
                  ))
                }
            </div>
        </div>

        <div className='w-5/6 md:order-1'>
            <MetaverseManager selectedMetaverse={selectedMetaverse}  page={page} setPage={setPage}/>
        </div>

        
    </div>
  )
}

export default Chains