import React, { useEffect, useState } from "react";
import LatestScreenshots from "../../Components/LatestScreenshots";
import LatestVideo from "../../Components/LatestVideo";
import SocialBox from "../../Components/SocialBox";
import Comment from "./Components/Comment";
import Post from "./Components/Post";
import bgTop from "../../assets/images/bg-top-5.png";
import Title from "./Components/Title";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import { secretKey } from "../../utils/secretKey";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs";

const NewsPage = (props) => {
  const [post, setPost] = useState(null);
  const params = useParams();
  const { slug } = params;

  const fetchData = async () => {
    const response = await axios.post(`${serverURL}news/${slug}`, {
      key: secretKey,
    });
    //console.log(response);
    if (response.status === 200) {
      setPost(response.data.news);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="nk-gap" />
      <BreadCrumbs
        breadCrumbs={[
          { text: "HOME", link: "/" },
          { text: "NEWS", link: "/news" },
        ]}
      />

      <Title title={post?.title} />
      <div className="nk-gap-2" />
      <div className="row vertical-gap">
        <div className="col-lg-8">
          <Post post={post} />
          <div className="nk-gap-2" />
        </div>
        <div className="col-lg-4 nk-sidebar-sticky-parent">
          {/* <SocialBox /> */}
          <LatestVideo />
          <LatestScreenshots />
        </div>
      </div>
      <div className="nk-gap-4"></div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default NewsPage;
