import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";

const Footer = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const reCaptcha = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInvalid(false);

    if (token) {
      if (message && name && email) {
        const data = {
          message: message,
          name: name,
          email: email,
        };

        try {
          const response = await axios.post(`${serverURL}send-email`, data);
          if (response && response.status === 200) {
            setSuccess("Mail Sent");
          }
        } catch (error) {
          if (error.response.status === 406) {
            setInvalid(true);
          }
          setSuccess("");
        }
        setError("");
      } else {
        setError("Please Fill up all the fields");
      }
    } else {
      setError("Please Verify You are a human");
    }
  };

  return (
    <footer className="nk-footer">
      <div className="container">
        <div className="nk-gap-3"></div>
        <div className="row vertical-gap">
          <div className="col-md-6">
            <div className="nk-widget">
              <h4 className="nk-widget-title">
                <span className="text-main-1">Contact</span> With Us
              </h4>
              <div className="nk-widget-content">
                <form
                  className="nk-form nk-form-ajax"
                  noValidate="noValidate"
                  onSubmit={handleSubmit}
                >
                  <div className="row vertical-gap sm-gap">
                    <div className="col-md-6">
                      <input
                        type="email"
                        className="form-control required placeholder:text-white"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email *"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control required placeholder:text-white"
                        name="name"
                        placeholder="Name *"
                      />
                    </div>
                  </div>
                  <div className="nk-gap"></div>
                  <textarea
                    className="form-control required placeholder:text-white"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="5"
                    placeholder="Message *"
                  ></textarea>
                  <div className="nk-gap-1"></div>
                  <button
                    data-action="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="nk-btn nk-btn-rounded nk-btn-color-white mb-2"
                  >
                    <span>Send</span>
                  </button>
                  <ReCAPTCHA
                    ref={reCaptcha}
                    sitekey="6Lch6xcpAAAAABKQ28IX_MUal7xGadlZTM1D1NAg"
                    onChange={(token) => setToken(token)}
                    onExpired={(e) => setToken(null)}
                  />
                  <div className="nk-form-response-success">{success}</div>
                  <div className="nk-form-response-error">{error}</div>
                  {invalid && (
                    <div className="nk-form-response-error">Invalid Email</div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-gap-3"></div>
      </div>
      <div className="nk-copyright">
        <div className="container">
          <div className="nk-copyright-left">            
            Copyright © 2024
          </div>
          <div className="nk-copyright-right">
            <ul className="nk-social-links-2">             
              
              <li>
                <a className="nk-social-facebook" href="https://www.facebook.com/RareDotLand">
                  <svg
                    className="svg-inline--fa fa-facebook fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                    ></path>
                  </svg>{" "}
                  <span className="fab fa-facebook"></span>{" "}
                </a>
              </li>

              <li>
                <a
                  className="nk-social-twitter"
                  href="https://twitter.com/RareDotLand"
                  target="_blank"
                >
                  <svg
                    className="svg-inline--fa fa-twitter fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="twitter"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    ></path>
                  </svg>{" "}
                  <span className="fab fa-twitter"></span>{" "}
                </a>
              </li>             
              
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
