import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./Components/Header/NavBar";
import TopBar from "./Components/Header/TopBar";
import HomePage from "./Pages/HomePage/HomePage";
import axios from "axios";
import bgBottom from "./assets/images/bg-bottom.png";
import Footer from "./Components/Footer/Footer";
import NewsPage from "./Pages/NewsPage/NewsPage";
import ScrollToTop from "./Components/ScrollToTop";
import ReactGA from "react-ga4";
import NewsSubPage from "./Pages/NewsSubPage/NewsSubPage";
import MobileNav from "./Components/Header/MobileNav";
import MetaversesPage from "./Pages/MetaversesPage/MetaversesPage";
import SingleMetaversePage from "./Pages/MetaversesPage/SingleMetaversePage";
import { serverURL } from "./utils/serverURL";
import Login from "./Pages/Login/Login";
import Registration from "./Pages/Registration/Registration";
import Profile from "./Pages/Profile/Profile";
import Forgot from "./Pages/Forgot/Forgot";
import Reset from "./Pages/Reset/Reset";
const App = () => {
  const Tracking_Id = "G-MGX95VTB9P";
  ReactGA.initialize(Tracking_Id);
  ReactGA._gaCommandSendPageview(document.location.pathname);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkToken = async () => {
    try {
      const tokenValue = sessionStorage.getItem("token");
      if (tokenValue) {
        const response = await axios.post(
          `${serverURL}user/verifyToken`,
          {
            token: tokenValue,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.status === 200) {
          setIsLoggedIn(true);
        } else {          
          setIsLoggedIn(false);
          alert("not logged");
        }
      }
    } catch (error) {
      console.log("Error in token checking");
      console.log(error);
      setIsLoggedIn(false);
    }
  };
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div className="">
      <Router>
        <TopBar />
        <NavBar />
        <MobileNav />

        <Routes>
          <Route path="/news" element={<NewsPage />} />
          <Route
            path="/profile"
            element={isLoggedIn ? <Profile /> : <Login />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/news/:slug" element={<NewsSubPage name="hassan" />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/metaverses" element={<MetaversesPage />} />
          <Route
            exact
            path="/metaverses/:id"
            element={<SingleMetaversePage />}
          />
        </Routes>
        <Footer />
      </Router>

      <img className="nk-page-background-bottom" src={bgBottom} alt="" />
    </div>
  );
};

export default App;
