import React, { useState } from "react";
import axios from "axios";
import { serverURL } from "../../utils/serverURL";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import bgTop from "../../assets/images/bg-top.png";
import ReCAPTCHA from "react-google-recaptcha";

const Registration = () => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [userCredentials, setCredentials] = useState({
    username: "",
    email: "",
    password: "",
    walletAddress: "",
  });

  const validateInputs = () => {
    const { username, email, password, walletAddress } = userCredentials;

    if (!username.trim()) {
      alert("Please enter a username.");
      return false;
    }

    if (!email.trim()) {
      alert("Please enter an email.");
      return false;
    }

    if (!password.trim()) {
      alert("Please enter a password.");
      return false;
    }

    if (!/^[a-zA-Z0-9]+$/.test(walletAddress.trim())) {
      alert("Wallet address should only contain letters and numbers.");
      return false;
    }

    return true;
  };
  const handleRecaptchaChange = (value) => {    
    // Set the state based on the verification status
    setIsCaptchaVerified(value !== null);
  };
  const checkCredentials = async () => {
    if (!isCaptchaVerified) {
      alert("Please verify captcha first");
      return;
    }
    if (!validateInputs()) {
      return;
    }

    try {
      const response = await axios.post(`${serverURL}user/newUser`, {
        credentials: userCredentials,
      });      

      if (response.status === 200) {
        alert("Registration Successful!");
        window.location.href = "/login";
      } else {
        alert("Something went wrong");        
      }
    } catch (error) {
      console.error("Error during registration:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="mainContainerL">
      <div className="loginContainer">
        <h2>
          <span className="signL">Sign</span> <span className="inL">Up</span>
        </h2>
        <br />
        <div className="descL">Registration details:</div>
        <form>
          <input
            className="loginformInput"
            type="text"
            id="username"
            value={userCredentials.username}
            onChange={(e) =>
              setCredentials({ ...userCredentials, username: e.target.value })
            }
            placeholder=" Full Name"
          />
          <br />
          <input
            className="loginformInput"
            type="email"
            id="email"
            value={userCredentials.email}
            onChange={(e) =>
              setCredentials({ ...userCredentials, email: e.target.value })
            }
            placeholder=" Email"
          />
          <br />
          <input
            className="loginformInput"
            type="password"
            id="password"
            value={userCredentials.password}
            onChange={(e) =>
              setCredentials({ ...userCredentials, password: e.target.value })
            }
            placeholder=" Password"
          />
          <br />
          <input
            className="loginformInput"
            type="text"
            id="walletAddress"
            value={userCredentials.walletAddress}
            onChange={(e) =>
              setCredentials({
                ...userCredentials,
                walletAddress: e.target.value,
              })
            }
            placeholder=" Wallet Address"
          />
          <br />
          <br />
          <ReCAPTCHA sitekey="6Lch6xcpAAAAABKQ28IX_MUal7xGadlZTM1D1NAg" onChange={handleRecaptchaChange} />
          <div>
            <button type="button" className="btnL" onClick={checkCredentials}>
              Sign Up
            </button>            
          </div>
          <div className="otherLinksL">
              <Link to="/forgot">Forgot Password?</Link>
              <Link to="/login">Already have an account? Sign In</Link>
            </div>
        </form>
      </div>
      <img className="nk-page-background-top" src={bgTop} alt="" />
    </div>
  );
};

export default Registration;
