import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverURL } from "../utils/serverURL";
import { Play } from "react-ionicons";
import ReactPlayer from "react-player/youtube";

const LatestVideo = () => {
  const [video, setVideo] = useState(null);

  const fetchData = async () => {
    const response = await axios.get(`${serverURL}video/`);
    if (response.status === 200) {
      setVideo(response.data.video[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="nk-widget nk-widget-highlighted mt-4">
        <h4 className="nk-widget-title">
          <span>
            <span className="text-main-1">Latest</span> Video
          </span>
        </h4>
        <div className="nk-widget-content">
          <ReactPlayer
            controls
            playbackRate={2}
            width="100%"
            height="100%"
            url={video?.url}
          />
        </div>
      </div>
    </div>
  );
};

export default LatestVideo;
