import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverURL } from "../utils/serverURL";
import { Play } from "react-ionicons";
import ReactPlayer from "react-player/youtube";

const MetaverseLatestVideo = ({metaverseId}) => {
  const [video, setVideo] = useState(null);

  useEffect(()=>{
    async function getLatestVideo(){
        const response = await axios.get(`${serverURL}metaverses/getAllVideos?metaverseId=${metaverseId}`)
        const videos = response.data?.message;
        const latestVideo = (videos && videos[0])? videos[videos.length-1] : ""
        setVideo(latestVideo) 
    }
    getLatestVideo()

  },[])
  

  return (
    <div>
      <div className="nk-widget nk-widget-highlighted mt-4">
        <h4 className="nk-widget-title">
          <span>
            <span className="text-main-1">Latest</span> Video
          </span>
        </h4>
        <div className="nk-widget-content">
          <ReactPlayer
            controls
            playbackRate={2}
            width="100%"
            height="100%"
            url={video}
          />
        </div>
      </div>
    </div>
  );
};

export default MetaverseLatestVideo;
