import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { imageExists } from "../../../utils/imageExists";
import { serverURL } from "../../../utils/serverURL";
import noImage from "../../../assets/images/notFound.svg";

const LatestNews = (props) => {
  const { news } = props;
  const [active, setActive] = useState(null);

  useEffect(() => {
    setActive(news[0]);
  }, [news]);

  return (
    <div>
      <h3 className="nk-decorated-h-2 text-3xl" >
        <Link to={`${window.location.protocol}//${window.location.host}/news`}>
          <span className="text-main-1 ">Latest</span> News
        </Link>
      
      </h3>
      <div className="nk-gap"></div>

      <div className="nk-news-box">
        <div className="nk-news-box-list">
          <div className="nano has-scrollbar">
            <div className="nano-content" tabIndex="0">
              {console.log(news)}
              {               
              news.slice(0, 6).map((post) => (
                
                <div
                className={`nk-news-box-item ${
                    active?._id === post?._id ? "nk-news-box-item-active" : ""
                  }`}
                  onClick={() => setActive(post)}
                >
                  <div className="nk-news-box-item-img">
                    <div
                      className="left-news-img"
                      style={{
                        backgroundImage: `url(${
                          serverURL + post.imageSmall &&
                          imageExists(serverURL + post.imageSmall)
                            ? serverURL + post.imageSmall
                            : noImage
                        })`,
                      }}
                    />
                  </div>
                  <img
                    src={
                      serverURL + post.imageSmall &&
                      imageExists(serverURL + post.imageSmall)
                        ? serverURL + post.imageSmall
                        : noImage
                    }
                    alt="Grab your sword and fight the Horde"
                    className="nk-news-box-item-full-img"
                  />
                  <h3 className="nk-news-box-item-title">{post.title}</h3>
                  <span className="nk-news-box-item-categories">
                    <span className="bg-main-1">{post.categories[0].name}</span>
                  </span>
                  <div className="nk-news-box-item-text">
                    <p>{post.body.substring(0, 300)}</p>
                  </div>
                  <Link to={`/news/${post.slug}`} className="nk-news-box-item-url">
                    Read More
                  </Link>
                  <div className="nk-news-box-item-date">
                    <FontAwesomeIcon icon={faCalendar} />{" "}
                    {post?.updatedAt?.toString().substring(0, 10)}
                  </div>
                </div>
              ))}
            </div>
            <div className="nano-pane">
              <div className="nano-slider"></div>
            </div>
          </div>
        </div>
        <div className="nk-news-box-each-info">
          <div className="nano has-scrollbar">
            <div className="nano-content" tabIndex="0">
              <div className="nk-news-box-item-image">
                <div
                  className="active-news-image"
                  style={{
                    backgroundImage: `url(${
                      serverURL + active?.imageMedium &&
                      imageExists(serverURL + active?.imageMedium)
                        ? serverURL + active?.imageMedium
                        : noImage
                    })`,
                  }}
                />
                <span className="nk-news-box-item-categories">
                  <span className="bg-main-4">{active?.categories[0].name}</span>
                </span>
              </div>
              <h3 className="nk-news-box-item-title">{active?.title}</h3>
              <div className="nk-news-box-item-text">
                <p>{active?.body.substring(0, 200)}</p>
              </div>
              <Link to={`/news/${active?.slug}`} className="nk-news-box-item-more">
                Read More
              </Link>
              <div className="nk-news-box-item-date">
                <FontAwesomeIcon icon={faCalendar} />{" "}
                {active?.updatedAt?.toString().substring(0, 10)}
              </div>
            </div>
            <div className="nano-pane">
              <div className="nano-slider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-gap-2" />
      <div className="nk-blog-grid">
        <div className="row">
          {news.slice(6, 10).map((post) => (
            <div className="col-md-6 col-lg-3">
              <div className="nk-blog-post">
                <Link to={`/news/${post.slug}`} className="nk-post-img">
                  <div
                    className="grid-img"
                    style={{
                      backgroundImage: `url(${
                        serverURL + post.image &&
                        imageExists(serverURL + post.image)
                          ? serverURL + post.image
                          : noImage
                      })`,
                    }}
                  />
                  <span className="nk-post-comments-count">13</span>
                  <span className="nk-post-categories">
                    <span className="bg-main-5">{post.categories[0].name}</span>
                  </span>
                </Link>
                <div className="nk-gap"></div>
                <h2 className="nk-post-title h4">
                  <Link to={`/news/${post.slug}`}>
                    {post.title.substring(0, 25)}
                  </Link>
                </h2>
                <div className="nk-post-text">
                  <p>{post.body.substring(0, 200)}</p>
                </div>
                <div className="nk-gap"></div>
                <Link
                  to={`/news/${post.slug}`}
                  className="nk-btn nk-btn-rounded nk-btn-color-dark-3 nk-btn-hover-color-main-1"
                >
                  Read More
                </Link>
                <div className="nk-post-date float-right">
                  {" "}
                  {post.updatedAt?.toString().slice(0, 10)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
