import React from 'react'
import dayjs from 'dayjs';
import { MdOutlineDateRange } from "react-icons/md";
import { Link } from 'react-router-dom';



const NewsCard = ({imgSrc,title,slug,category,body,createdAt}) => {
    const displayBody = body? body.slice(0,200):"";
  return (
    <Link to={`/news/${slug}`}>
        <div className={`w-full textGrayColor`}>
        
                <div className={`w-full relative`}>
                    <img className={`w-full rounded`} src={imgSrc} alt='post image' />
                    <span className={`absolute top-10 left-0 text-white p-2 bg-red-600 rounded-2`}>{category}</span>

                </div>
                <div className='text-white'>
                    <h2 className='text-2xl  my-2 font-bold hover:text-red-600' >{title}</h2>
                </div>
                <div className={``}> 
                    <span></span>
                </div>
                <div className={``}> 
                    {displayBody}..........
                </div>

            
                <div className='flex g-x-3 justify-between items-center'>
                    <button className={`w-6/12 p-2 bgGrayColor text-white font-bold rounded my-4 hover:bg-red-600`}>Read More</button>
                    <div className='w-5/12 flex gap-2 justify-center items-center'>
                        <MdOutlineDateRange />
                        <span>{dayjs(createdAt).format('MMM DD, YYYY')}</span>
                    </div>
                </div>
        
        </div>
    </Link>
  )
}

export default NewsCard