import React from 'react'
import {Link} from "react-router-dom"
const BreadCrumbs = ({breadCrumbs}) => {
  return (
    <div>
        {
            breadCrumbs.map((item,index)=>(<span key={index} className='text-white font-bold text-lg'><Link  to={item.link}>{item.text.toUpperCase()}</Link><span className='px-2'>&gt;</span></span>))
        }
    </div>
  )
}

export default BreadCrumbs