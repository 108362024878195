import React, { useState } from 'react'
import { IoIosEye } from "react-icons/io";


const ImageComp = ({src,setMetaverseSlider}) => {
  const [hovered,setHovered] = useState(false)
  return (
    <div className='relative h-full w-full' onMouseOver={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}>
        <div className='h-full w-full'>
            <img src={src}  className='rounded h-full w-full border-2 border-gray-50'/>
        </div>
        {hovered &&
            <div onClick={setMetaverseSlider?()=>setMetaverseSlider(true):null} className='cursor-pointer absolute top-0 left-0 text-gray-50 h-full w-full rounded bgTransparentRed flex justify-center items-center text-4xl'>
              <IoIosEye className='border-2 border-gray-50 rounded-full' />
            </div>
        }
      
    </div> 
  )
}

export default ImageComp