import React from "react";
import Flickity from "react-flickity-component";
import slide1 from "../../../assets/images/Sandbox_Iceland.png";
import slide2 from "../../../assets/images/Sandbox_Islands.png";
import slide3 from "../../../assets/images/Sandbox_Dracoliche.png";

const ImageSlider = () => {
  const flickityOptions = {
    initialIndex: 2,
    autoPlay: true,
  };
  return (
    <div
    className="nk-image-slider flickity-enabled is-draggable"
      data-autoplay="8000"
      tabIndex="0"
    >
      <div className="" style={{ height: "360px" }}>
        <Flickity
          className={"carousel"} // default ''
          elementType={"div"} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
        >
          <div
            className="nk-image-slider-item"
            // style="position: absolute; left: 0%;"
            aria-hidden="true"
          >
            <img
              src={slide1}
              alt=""
              className="nk-image-slider-img"
              data-thumb="assets/images/Sandbox_Iceland.png"
            />
          </div>
          <div
            className="nk-image-slider-item"
            // style="position: absolute; left: 100%;"
            aria-hidden="true"
          >
            <img
              src={slide2}
              alt=""
              className="nk-image-slider-img"
              data-thumb="assets/images/Sandbox_Islands.png"
            />
          </div>
          
          <div
            className="nk-image-slider-item"
            // style="position: absolute; left: 100%;"
            aria-hidden="true"
          >
            <img
              src={slide3}
              alt=""
              className="nk-image-slider-img"
              data-thumb="assets/images/Sandbox_Dracoliche.png"
            />
          </div>
          
        </Flickity>
      </div>

      <div className="nk-image-slider-content nk-image-slider-content-visible">
        <div className="nano has-scrollbar">
          <div
            className="nano-content"
            tabIndex="0"
            //   style="right: -15px;"
          >
            <h3 className="h4">Looking at Sandbox Metaverse</h3>
            <p className="text-white">
              Here we look at one of the most populare blockchain metaverses. Game developers are busy building...
            </p>
            <a
              href="#"
              className="nk-btn nk-btn-rounded nk-btn-color-white nk-btn-hover-color-main-1"
            >
              Read More
            </a>
          </div>
          <div
            className="nano-pane"
            //   style="display: none;"
          >
            <div
              className="nano-slider"
              //   style="height: 258px; transform: translate(0px, 0px);"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
